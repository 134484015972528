th_maps.onLoad(function () {

    th_maps.addMarkerIcon('default', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/maurienne/assets/images/markers/ico-marker-map-2x.png',
        shadowUrl: null,
        iconSize: [40, 40],
        iconAnchor: [20, 20]
    });

    th_maps.addMarkerIcon('hover', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/maurienne/assets/images/markers/ico-marker-map-hover-2x.png',
        shadowUrl: null,
        iconSize: [80, 80],
        iconAnchor: [40, 40]
    });

});

th_maps.init(thConfig.map.init);