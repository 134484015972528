

update_open_status();

function update_open_status() {
    $('.card-sit').each(function () {

        var sit_id = $(this).data('id');
        var current_card = $(this);


        $.ajax({
            url: ajaxurl,
            type: 'POST',
            data: {
                action: 'update_open_status',
                apidae_id: sit_id
            },
            success: function(response) {
                if(response.success && response.data.show) {
                    $('.js-ajax-ouverture',current_card).addClass('ouverture '+response.data.status).text(response.data.sttatus_text);
                }
                console.log(response);
            }
        });
    });
}


