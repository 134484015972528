//spécificités listing Agenda
parseCardAgendaClickOverlay();

// OVERLAY MAP
function callbackMapOverlayMap(macarte) {
    macarte.setZoom(14);
    macarte.zoomControl.setPosition('topleft');
}


// PAGE LISTING SIT
function callbackMapPageListingLEI(macarte) {
    macarte.setZoom(14);
    macarte.zoomControl.setPosition('topright');
}


// listing SIT classique - uniquement une map en overlay
var listingSitDefault = new thListingManager({
    selectors:{
        mapoverlay:'#map-listing',
    },
    mapOptions: {
        onlyDesktop: true,
        markerIconHover: 'hover'
    },
    mapOverlayOptions: {
        onlyMobile: true,
        markerIconHover: 'hover'
    },
    update_form: true,
    afterUpdate: function () {
        $('.facette-radio, .facette-checkbox label, .facette-switch').append('<span></span>');
        initDropdown();
        th_overlay.parse($('.js-listing-form-facette'));
    }
});

// listing SIT FMA - uniquement une map en overlay + overlay sur les cards
var listingSitFMA = new thListingManager({
    selectors:{
        listing: '.js-listing-fma',
        mapoverlay:'#map-listing',
    },
    mapOverlayOptions: {
        markerIconHover: 'hover'
    },
    afterUpdate: function () {
        parseCardAgendaClickOverlay();
    }
});

// listing CPT - pas de map
var listingCPT = new thListingManager({
    selectors:{
        listing: '.js-listing-cpt',
    },
});


/* A l'ouverture de l'overlay, on charge la carte */
// cette fonction doit $etre déclarée avant le th_overlay.init()
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-map') {
        setTimeout(function () {
            if(listingSitDefault.loaded) {
                listingSitDefault.initMapOverlay();
            }
            if(listingSitFMA.loaded) {
                listingSitFMA.initMapOverlay();
            }
        }, 250);
    }
});



/* Initialisation du slider de la carte */

(function () {

    var sliders = document.querySelectorAll('.overlay-map .th-slider');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }

    function initSlider(slider) {
        var parent = slider.parentNode;

        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var dotsWrapper = parent.querySelectorAll('.nav-dots');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: '1024',
            oninit: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper[0]);
                }

            },
            onchange: function (slider) {
                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                }
            }
        });

    }


})();



function initDropdown(){
    $('.barre-listing-sit .group-checkbox .title-facette').on('click', function(e){
        e.preventDefault();
        $(this).parent().toggleClass('open');
    });
}
//On init que si besoins
if($('.barre-listing-sit .group-checkbox').length > 0){
    initDropdown();
}