// Selectric est activé par défaut sur toutes les selects,
// mais nous on le veut pas avoir sur le sélecteur de langues en version tablette / mobile
$("#lang-dropdown-mobile").selectric('destroy');

// Ce script est nécessaire pour forcer le changement de page au click sur une option
$('#lang-dropdown-mobile').change(function () {
    var val = $(this).val();
    window.location.href = val;
});

if($('#overlay-menu .menu').length > 0){
    var container = $('#overlay-menu .container');
    var menu = $('#overlay-menu .menu >ul');

    $('.has-sub-menu>a', menu).on('click', function(e){
        e.preventDefault();


        if(!$(this).hasClass('active')){
            //On "ferme" tout avant d'ouvrir le menu cliqué
            $('.has-sub-menu a', menu).removeClass('active');
            $('.sub-menu', menu).removeClass('open');
            //On ouvre le menu cliqué
            $(this).addClass('active');
            $(this).parent().find('ul').addClass('open');

            //On change la couleur de fond
            var color = $(this).attr('data-color');
            container.attr('data-color', color);
            // On ajoute la classe opened sur le parent
            menu.addClass('opened');
        }else{
            //On "ferme" tout avant d'ouvrir le menu cliqué
            $('.has-sub-menu a', menu).removeClass('active');
            $('.sub-menu', menu).removeClass('open');
            //On reset la couleur de fond
            container.attr('data-color', '');
            // On remove la classe opened sur le parent
            menu.removeClass('opened');
        }

    });

    $('.retour').on('click', function(){
        //On "ferme" tout avant d'ouvrir le menu cliqué
        $('.has-sub-menu a', menu).removeClass('active');
        $('.sub-menu', menu).removeClass('open');
        //On reset la couleur de fond
        container.attr('data-color', '');
        // On remove la classe opened sur le parent
        menu.removeClass('opened');
    });
}




setTimeout(function(){
    $('#overlay-menu .menu >ul >li >ul').each(function(){
        var contentHeight = 0;
        var height = $(this).height();

        $(this).find('>li').each(function(){
            contentHeight = contentHeight + $(this).height();
        });


        if(contentHeight > height){
            $(this).find('li.next').addClass('visible');
        }
    });

    $('.next.visible').on('click', function(){
        var scrolltopage = $(this).parent().scrollTop() + 200;
        $(this).parent().animate({scrollTop:scrolltopage},500);
    });

}, 3000);