var parcoursUsager = {
    wrapper: $('.parcours-usager'),

    currentOnlet:false,


    init: function(){
        parcoursUsager.listenner();
    },

    //écoute des clics sur les différents élément (onglets, accordéons)
    listenner: function(){
        //Onglets
        $('div[name="parcours-usager"]', parcoursUsager.wrapper).on('click', function(){
            var target = $(this).attr('id');
            parcoursUsager.changeOnglet(target);
        });

        //Accordeons
        $('.accordeon-title', parcoursUsager.wrapper).on('click', function(){
            parcoursUsager.accordeon($(this));
        });
    },

    //Changement de l'onglet actif
    changeOnglet: function(target){
        console.log(document.body.clientWidth);
        if(document.body.clientWidth <= 980 && $('.accordeons .onglet-content[data-onglet="'+target+'"]', parcoursUsager.wrapper).hasClass('checked')){
            // if($('.onglets', parcoursUsager.wrapper).hasClass('open')){
            //     $('.onglets', parcoursUsager.wrapper).addClass('open');
            // }else{
            //     $('.onglets', parcoursUsager.wrapper).addClass('open');
            // }
            $('.onglets', parcoursUsager.wrapper).toggleClass('open');
            console.log('ouverture mobile');

        }else{

            $('.onglets .onglet.checked', parcoursUsager.wrapper).removeClass('checked');
            $('.accordeons .onglet-content.checked', parcoursUsager.wrapper).removeClass('checked');

            $('.onglets .onglet[id="'+target+'"]', parcoursUsager.wrapper).addClass('checked');
            $('.accordeons .onglet-content[data-onglet="'+target+'"]', parcoursUsager.wrapper).addClass('checked');

            $('.onglets', parcoursUsager.wrapper).removeClass('open');
        }
    },

    //Ouverture fermeture des accordéons
    accordeon: function(obj){
        obj.toggleClass('actif');
    }

};
parcoursUsager.init();