(function () {

    var sliders = document.querySelectorAll('.bloc-carte .th-slider');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }

    function initSlider(slider) {
        var parent = slider.parentNode;
        while (!parent.className.match(/bloc-carte/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var dotsWrapper = parent.querySelectorAll('.nav-dots');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: '1024',
            oninit: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper[0]);
                }

            },
            onchange: function (slider) {
                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                }
            }
        });

    }


})();


// PAGE LISTING SIT
function callbackMapBlockSlider(macarte) {

    macarte.zoomControl.setPosition('topright');

    var markers = [];
    $('.js-bloc-carto .card', $(macarte._container).parents('.bloc')).each(function () {

        var latlng = {lat: this.getAttribute('data-lat') * 1, lng: this.getAttribute('data-lng') * 1};

        if(latlng.lat && latlng.lng) {

            var marker = th_maps.createMarker(macarte, latlng, 'default');

            macarte.on("click", function (e) {
                marker.setIcon(th_maps.markersIcons['default']);
            });

            // Lorsqu'on ouvre un popup on change l'icône pour ensuite remettre à la fermeture celle-là de default
            marker.on("popupopen", function (e) {
                marker.setIcon(th_maps.markersIcons['hover']);
            });

            marker.on("popupclose", function (e) {
                marker.setIcon(th_maps.markersIcons['default']);
            });

            // Lorsqu'on fait un hover sur une card, on change l'icône du marqueur
            $(this).on({
                mouseenter: function () {
                    marker.setIcon(th_maps.markersIcons['hover']);
                },
                mouseleave: function () {
                    marker.setIcon(th_maps.markersIcons['default']);
                }
            });


            th_maps.createInfoWindow(this.outerHTML, marker, 260);
            markers.push(marker);
        }
    });

    if(markers.length > 0) {
        var group = new L.featureGroup(markers);
        macarte.fitBounds(group.getBounds());
    }

    if (isTouchDevice()) {
        macarte.dragging.disable();
    }
}