if($('.bloc-form').length > 0){
    var formFocus = $('.bloc-form input[type="date"], .bloc-form input[type="number"], .bloc-form input[type="email"], .bloc-form input[type="tel"], .bloc-form input[type="number"], .bloc-form input[type="text"], .bloc-form textarea');

    //Au chargement de la page on vérifie si le champ est remplis
    formFocus.each(function(){
        if($(this).val() == ''){
            $(this).parent().removeClass('focused');
        }else{
            $(this).parent().addClass('focused');
        }
    });

    // Au focus
    formFocus.on('focusin', function(){
        $(this).parent().addClass('focused');
    });

    //A la sortie du champs
    formFocus.on('blur', function(){
        if($(this).val() == ''){
            $(this).parent().removeClass('focused');
        }
    });


    $('.frm_switch_block').parent().parent().addClass('classic-label');
}