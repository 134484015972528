var appr = {
    button: {
        like: $('#like'),
        dislike: $('#dislike')
    },
    postId: false,


    init: function(){
        if($('#appreciations').length > 0){

            //On récupère le postID via l'attribute dans le HTML
            appr.postId = $('#appreciations').attr('data-postid');

            //Vérifier si l'utilisateur n'a pas déjà laissé son avis, si c'est le cas on cache
            if(appr.storageCheck()){
                appr.hide();
            }
            appr.events();
        }
    },

    /**
     * Création des évènements de clic
     * */
    events: function(){
        appr.button.like.on('click', function(){
            appr.like();
        });
        appr.button.dislike.on('click', function(){
            appr.dislike();
        });
    },

    /**
     * Au clic sur le bouton like on met à jour la meta en BDD
     * */
    like: function(){
        //Si le bouton n'est pas déjà actif on incremente
        if(!appr.button.like.hasClass('active')){
            appr.ajax('like');
        }
    },

    /**
     * Au clic sur le bouton dislike
     * */
    dislike: function(){
        //Si le bouton n'est pas déjà actif
        if(!appr.button.dislike.hasClass('active')){
            appr.ajax('dislike');
        }
    },

    /**
     * Lancement de la fonction ajax en fontion des paramètres :
     * target : Meta cible (like ou dislike)
     * */
    ajax: function(target){
        $.ajax({
            url: ajaxurl,
            type: 'POST',
            data: {
                action: 'appreciation_action',
                method: target,
                post_id: appr.postId
            },
            success: function() {
                //En cas de succès on continue le fonctionnement
                appr.hide();
                appr.updateStorage();
                if(target == "dislike"){
                    appr.overlay();
                }
            }
        });
    },


    /**
     * Cache les boutons like/dislike et affiche le message de remerciement si le vote a déjà été effectué
     * */
    hide: function(){
        $('#appr_message').addClass('show');
        $('#appreciations').addClass('noChoice');
        $('#appreciations .valeurs').addClass('hide');
    },

    /**
     * Ouvre l'overlay "avis" lors du dislike
     * */
    overlay: function(){
        console.log('overlay');
    },


    /**
     * Check du storage afin de vérifier si l'utilisateur a déjà laissé son avis lors de sa visite
     * true : la valeur est présente on cache le like/dislike
     * false : l'utilisateur n'a encore fait aucune action
     * */
    storageCheck: function(){
        var id = appr.postId.toString(); //ID de la page
        var value = localStorage.getItem('appreciations');

        // Si la valeur dans le localstorage n'existe pas alors on la créer
        if(value == null){
            var store = {};
            store[id] = false;
            localStorage.setItem('appreciations', JSON.stringify(store));

        }else{
            //Si la valeur est à true ça veut dire qu'on cache le choix like/dislike
            if(JSON.parse(value)[id] == true){
                appr.hide();
                return true;
            }
        }

        return false;
    },

    /**
     * Mise à jour des cookies pour ne pas réafficher le choix de like/dislike pour l'utilisateur
     * */
    updateStorage: function(){
        var id = appr.postId.toString(); //ID de la page
        var value = localStorage.getItem('appreciations');

        // On change la valeur du local storage
        value = JSON.parse(value);
        value[id] = true;
        localStorage.setItem('appreciations', JSON.stringify(value))
    }

};

appr.init();