// Dans le "bloc" sit à propos, on ajoute un bouton pour voir plus ou moins si le texte à dépassé une hauteur de 400px
var sitProposHeight = $('.single-sit .text');
var btnShowMorePropos = $('.single-sit .btn-view-more');

sitProposHeight.each(function () {
    if ($(this).height() >= 120) {
        $(this).parent().addClass('view-more-active');

        btnShowMorePropos.on('click', function () {
            $(this).prev().toggleClass('toggle-height');
        });
    }
});

// PAGE SINGLE SIT
function callbackMapSingleSIT(macarte) {
    macarte.zoomControl.setPosition('topright');
    macarte.setZoom(14);
    if (isTouchDevice()) {
        macarte.dragging.disable();
    }
}


$('.carte-sit').on('click', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($(this).attr('href')).offset().top
    }, 500);
});


(function () {

    var sliders = document.querySelectorAll('.header-edito.single-sit-header .th-slider:not(.no-autoload), .sit-horaires .th-slider:not(.no-autoload)');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }


    function initSlider(slider) {
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var dotsWrapper = parent.querySelectorAll('.nav-dots');


        var reverse = 0;
        if (slider.className.match(/reverse/) && !isTabletPortraitOrSmalller()) {
            reverse = 1;
        }

        var s = new thSlider(slider, {
            draggable: true,
            rtl: reverse,
            scrollListener: true,
            oninit: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper[0]);
                }

            },
            onchange: function (slider) {

                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                }
            }
        });

    }

})();


// Input radio - changement de l'aspect
var aspectContainer = $("label input", "#aspects-choice");

if (aspectContainer.length) {
    aspectContainer.on("click", function (e) {
        e.preventDefault();

        var link = this.getAttribute("data-link");

        if (link) {
            window.location.href = link;
        }
    });
}


//Ajout d'une classe quand la date est stické dans le listing agenda
(function () {
    if($(".stick-temoins").length > 0 ){
        var listingObserver = new IntersectionObserver(function(entries) {
            // no intersection with screen
            if(entries[0].intersectionRatio === 0)
                document.querySelector(".stick-temoins").classList.add("sticked");
            // fully intersects with screen
            else if(entries[0].intersectionRatio === 1)
                document.querySelector(".stick-temoins").classList.remove("sticked");
        }, { threshold: [0,1] });

        listingObserver.observe(document.querySelector(".stick-temoins"));
    }
})();

(function () {
    if($(".single-sit .ouverture").length > 0 ){
        var fiche_url = window.location.href + '?' + Math.random();
        $.get(fiche_url, (responseHTML) => {
            var ouverture = $(responseHTML).find('.ouverture').html();
            $(".ouverture").html(ouverture).removeClass('hide');
        });
    }
})();