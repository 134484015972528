// ouverture de l'overlay des cards agenda sur le listingfma
function parseCardOffreEmploiClickOverlay(){
    $('.card:not(.parsed).js-open-overlay-offreemploi').on('click',function (e) {
        e.preventDefault();

        var elem = $(this);

        var sit_url = elem.attr('href');
        var overlay_id = 'overlay-offreemploi';

        $('#' + overlay_id + ' [class^=\'js-\']').html('');

        $.get(sit_url, function (responseHTML) {
            console.log($('.js-type',responseHTML).text());
            $('#' + overlay_id + ' .js-title').text($('.js-title',responseHTML).text());
            $('#' + overlay_id + ' .js-type').text($('.js-type',responseHTML).text());
            $('#' + overlay_id + ' .js-surtitre').text($('.js-surtitre',responseHTML).text());
            $('#' + overlay_id + ' .js-date').text($('.js-date',responseHTML).text());
            $('#' + overlay_id + ' .js-intro').html($('.js-intro',responseHTML).html());
            $('#' + overlay_id + ' .js-content').html($('.js-content',responseHTML).html());
        });

        th_overlay.open(overlay_id, true, true, false);


    }).addClass('parsed');
}

parseCardOffreEmploiClickOverlay();